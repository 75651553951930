<template>
  <div class="body">
    <!-- <Header><span>混合计划</span></Header> -->
    <div class="contain">
      <div class="_SelectHeader">
        <div class="_Select">
          <span>公司：</span>
          <el-select v-model="company" clearable placeholder="请选择" @change="changeCompany">
            <el-option
              v-for="item in companyUserList"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <el-button type="success" icon="el-icon-search" class="find" @click="searchData">搜索</el-button>
        <el-button type="primary" @click="drawQuestion">
          抽取题目
          <i class="el-icon-circle-plus-outline"></i>
        </el-button>
        <img
          v-show="
            new RegExp('股份公司管理员').test(roleName) ||
            (new RegExp('公司管理员').test(roleName) &&
              this.$route.query.fromQuery == 3)
          "
          @click="deleteItem"
          src="../../assets/images/Examine_img/del.png"
        />
      </div>
      <div class="table">
        <el-table
          :data="tableData"
          style="width: 100%"
          :height="elementHeight"
          :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
          id="table"
          highlight-current-row
          :row-class-name="tableRowClassName"
          :row-style="selectedstyle"
          @row-dblclick="rowDblclick"
          @row-click="rowClick"
        >
          <el-table-column type="index" label="序号" width="70"></el-table-column>
          <el-table-column prop="compName" label="被审公司" width="110"></el-table-column>
          <el-table-column prop label="部门">
            <template slot-scope="scope">
              <span>
                {{
                scope.row.deptName
                ? scope.row.deptName
                : scope.row.dutyDeptName
                ? scope.row.dutyDeptName
                : scope.row.inOutDeptName
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="postName" label="岗位" width="140"></el-table-column>
          <el-table-column prop="titleCount" label="抽取问题数" width="100"></el-table-column>
          <el-table-column prop="failCount" label="问题点数" width="100"></el-table-column>
          <el-table-column prop="status" label="检查状态" width="100">
            <template slot-scope="scope">
              <span v-show="scope.row.status === 0">未抽题</span>
              <span v-show="scope.row.status === 1">已抽题</span>
              <span v-show="scope.row.status === 2">完成</span>
              <span v-show="scope.row.status === 3">整改完成</span>
              <span v-show="scope.row.status === 4">未完成</span>
            </template>
          </el-table-column>
          <el-table-column prop="saveTime" label="创建时间" width="110">
            <template slot-scope="scope">
              <span>{{ scope.row.saveDate | time }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="saveName" label="创建人" width="100"></el-table-column>
          <el-table-column prop="type" label="抽题项" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="score" label="得分" width="100"></el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="curSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableDataCount"
        style="text-align: end; margin-top: 10px; margin-right: 10px"
      ></el-pagination>
    </div>
    <el-dialog title="抽取题目" :visible.sync="dialogVisible" width="35%" top="1vh">
      <div class="fourbox">
        <span>抽题类型：</span>
        <el-checkbox-group v-model="checkList">
          <!-- 隐藏考核细则，拍在内外审后面 -->
          <el-checkbox
            v-for="(item, index) in [
              /*'现场检查',*/
              '文件落地',
              '内外审问题点',
              '部门领导审核',
              '指派考试',
            ]"
            :key="index"
            @change="checkedBtn"
            :label="item"
            :disabled="item == '现场检查'"
          ></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="draw-contain">
        <div style="background-color: #f6f6f6; padding: 0 10px" v-if="showDialog1">
          <p
            style="
              padding: 10px;
              font-weight: bold;
              color: black;
              border-bottom: 1px solid #e5e5e5;
            "
          >现场检查</p>
          <div style="margin: 10px 0">
            <span style="margin: 10px">所属区域</span>
            <el-select v-model="areaId" placeholder="请选择">
              <el-option
                v-for="item in areaList"
                :key="item.value"
                :label="item.areaName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div style="display: flex">
            <span style="margin: 10px">备注消息</span>
            <textarea v-model="remark1" style="padding: 10px"></textarea>
          </div>
          <div style="margin-bottom: 10px; padding-bottom: 10px">
            <div style="display: flex">
              <span style="margin: 10px">抽取题目</span>
              <span style="margin: 10px; color: #4274c0; cursor: pointer" @click="chooseCt">
                <i class="el-icon-plus"></i> 添加范畴
              </span>
            </div>
            <div style="display: flex">
              <span style="margin: 15px; margin-left: 10%"></span>
              <div class="topic">
                <div>涉及方面</div>
                <div>总题数</div>
                <div>抽题数目</div>
                <!-- <img
                src="../../assets/images/Examine_img/top.png"
                @click="isshow = !isshow"
                alt=""
                />-->
              </div>
            </div>
            <el-collapse-transition>
              <div>
                <div style="display: flex" v-for="(item, index) in titlelist1" :key="index">
                  <span
                    style="
                      margin: 15px;
                      margin-left: 10%;
                      margin-bottom: 0px;
                      margin-top: 0px;
                    "
                  ></span>
                  <div class="topic1">
                    <div>{{ item.auditTypeName }}</div>
                    <div class="Centernumbet">
                      <span>{{ item.titleCount }}</span>
                    </div>
                    <div id="number">
                      <img
                        @click="sub(item)"
                        src="../../assets/images/Examine_img/slices/del1.png"
                        alt
                      />
                      <input
                        type="text"
                        style="ime-mode: disabled"
                        @keyup="test(item)"
                        v-model="item.extractCount"
                      />
                      <img
                        @click="plus(item)"
                        src="../../assets/images/Examine_img/slices/plus1.png"
                        alt
                      />
                    </div>
                  </div>
                </div>
              </div>
            </el-collapse-transition>
          </div>
        </div>

        <div style="background-color: #f6f6f6; padding: 0 10px" v-if="showDialog2">
          <p
            style="
              padding: 10px;
              font-weight: bold;
              color: black;
              border-bottom: 1px solid #e5e5e5;
            "
          >文件落地</p>
          <div style="margin: 10px 0">
            <span style="margin: 10px">所属部门</span>
            <el-select v-model="fileDept" clearable @change="togetPost" placeholder="请选择">
              <el-option
                v-for="item in deptList"
                :key="item.value"
                :label="item.deptmentName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div style="margin: 10px 0">
            <span style="margin: 10px">所属岗位</span>
            <el-select v-model="filePost" @change="changePost" clearable placeholder="请选择">
              <el-option
                v-for="item in filePostList"
                :key="item.value"
                :label="item.postName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div>
            <div style="display: flex">
              <span style="margin: 10px">抽取题目</span>
              <el-table :data="titlelist2" @selection-change="selectionDraw" style="width: 100%">
                <el-table-column prop="fileName" label="文件名称" width="150"></el-table-column>
                <el-table-column prop="fileNo" label="文件编号"></el-table-column>
                <el-table-column prop="titleCount" label="总题数"></el-table-column>
                <el-table-column type="selection" width="55"></el-table-column>
              </el-table>
            </div>
          </div>
          <div style="margin: 10px 0; padding-bottom: 10px; display: flex">
            <span style="margin: 0 10px">专题指派</span>
            <div @click="showAssignStudy">
              <i style="padding: 0 10px; color: #0091ff" class="el-icon-plus"></i>
              <span style="color: #0091ff">
                指派考试（人数{{
                JSON.stringify(assignAuditTest) != '{}'
                ? assignAuditTest.studyUserId.split(',').length
                : userId.length
                }}）
              </span>
            </div>
          </div>
        </div>
        <div
          style="
            background-color: #f6f6f6;
            padding: 0 10px 10px 10px;
            margin-bottom: 10px;
          "
          v-if="showDialog3"
        >
          <p
            style="
              padding: 10px;
              font-weight: bold;
              color: black;
              border-bottom: 1px solid #e5e5e5;
            "
          >内外审问题点</p>

          <div style="margin: 10px 0">
            <span style="margin: 10px">抽取类型</span>
            <el-select v-model="extractionType" multiple collapse-tags placeholder="请选择">
              <el-option
                v-for="item in ['内审自查', '内审自纠', '外审自查', '外审自纠']"
                :key="item.value"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>

          <!-- <div style="margin: 10px 0">
            <span style="margin: 10px">抽取类型</span>
            <el-select v-model="inOutAuditType" placeholder="请选择">
              <el-option
                v-for="item in ['内审', '外审']"
                :key="item.value"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>-->
          <div style="margin: 10px 0">
            <span style="margin: 10px">所属部门</span>
            <el-select v-model="auditDept" @change="changeAuditFun" placeholder="请选择">
              <el-option
                v-for="item in deptList"
                :key="item"
                :label="item.deptmentName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <!-- <div style="margin: 10px 0">
            <span style="margin: 10px">自查自纠</span>
            <el-select
              v-model="checkStatus"
              @change="changeAuditFun"
              placeholder="请选择"
            >
              <el-option
                v-for="item in ['自查', '自纠']"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>-->
          <div class="year" style="margin: 10px 0">
            <span style="margin: 10px">选择年份</span>
            <el-date-picker
              v-model="year"
              format="yyyy"
              value-format="yyyy"
              type="year"
              placeholder="选择年"
              @change="changeAuditFun"
            ></el-date-picker>
          </div>
          <div
            style="margin: 10px 0"
            v-if="
              extractionType.includes('外审自纠') ||
              extractionType.includes('内审自纠')
            "
          >
            <span style="margin: 10px">所属职能</span>
            <el-select v-model="auditFun" multiple placeholder="请选择">
              <el-option
                v-for="item in auditFunList"
                :key="item.value"
                :label="item.functionName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div style="background-color: #f6f6f6; padding: 0 10px" v-if="showDialog4">
          <p
            style="
              padding: 10px;
              font-weight: bold;
              color: black;
              border-bottom: 1px solid #e5e5e5;
            "
          >考核细则</p>
          <div style="margin: 10px 0">
            <span style="margin: 10px">抽取类型</span>
            <el-select v-model="assessType" @change="getTitle4" placeholder="请选择">
              <el-option v-for="item in ['安全考核', '客服考核']" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </div>
          <div style="display: flex" class="text">
            <span style="margin: 10px">添加备注:</span>
            <textarea v-model="remark4" style="padding: 10px"></textarea>
          </div>
          <div style="margin-bottom: 20px; margin-top: 10px" class="text">
            <div style="display: flex">
              <span style="margin: 10px; margin-left: 8.5px">抽取题目:</span>
              <div class="topic">
                <div>文件名称</div>
                <div>总题数</div>
                <div>抽题数目</div>
                <img src="../../assets/images/Examine_img/top.png" @click="isshow = !isshow" alt />
              </div>
            </div>
            <el-collapse-transition>
              <div>
                <div style="display: flex">
                  <span
                    style="
                      margin: 10px;
                      margin-left: 1.4%;
                      margin-bottom: 0px;
                      margin-top: 0px;
                    "
                  >&ensp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ensp;</span>
                  <div class="topic1">
                    <div>{{ titlelist4.fileName }}</div>
                    <div class="Centernumbet">
                      <span>{{ titlelist4.questionCount }}</span>
                    </div>
                    <div id="number">
                      <img
                        @click="sub(titlelist4)"
                        src="../../assets/images/Examine_img/slices/del1.png"
                        alt
                      />
                      <input
                        type="text"
                        style="ime-mode: disabled"
                        @keyup="test(titlelist4)"
                        v-model="titlelist4.extractCount"
                      />
                      <img
                        @click="plus(titlelist4)"
                        src="../../assets/images/Examine_img/slices/plus1.png"
                        alt
                      />
                    </div>
                  </div>
                </div>
              </div>
            </el-collapse-transition>
          </div>
        </div>
        <div style="background-color: #f6f6f6; padding: 0 10px" v-if="showDialog5">
          <p
            style="
              padding: 10px;
              font-weight: bold;
              color: black;
              border-bottom: 1px solid #e5e5e5;
            "
          >部门领导审核</p>
          <div style="margin: 10px 0">
            <span style="margin: 10px">所属部门</span>
            <el-select v-model="departmentHeads" clearable @change="gettitle5" placeholder="请选择">
              <el-option
                v-for="item in deptList"
                :key="item.value"
                :label="item.deptmentName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div>
            <div style="display: flex">
              <span style="margin: 10px">抽取题目</span>
              <el-table :data="titlelist5" @selection-change="selectionDraw5" style="width: 100%">
                <el-table-column prop="fileName" label="文件名称" width="150"></el-table-column>
                <el-table-column prop="fileNo" label="文件编号"></el-table-column>
                <el-table-column prop="titleCount" label="总题数"></el-table-column>
                <el-table-column type="selection" width="55"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          style="background-color: #4274c0; color: #ffffff"
          @click="drawReq"
          v-loading="isReq"
        >确定抽题</el-button>
        <el-button @click="handleCloseV">取消抽题</el-button>
      </span>
    </el-dialog>
    <el-dialog title="添加范畴" :visible.sync="dialogVisibleC" width="30%" :before-close="handleClose">
      <div style="height: 300px; overflow: hidden; overflow-y: auto">
        <el-tree
          :data="Ctdata"
          show-checkbox
          node-key="id"
          ref="tree"
          @check="iScheck"
          highlight-current
          :props="defaultProps"
        ></el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleC = false">取 消</el-button>
        <el-button type="primary" @click="getTitle1">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="添加指派考试人数"
      :visible.sync="showAssignDialog"
      width="40%"
      :before-close="closeAssignDialog"
    >
      <div style="margin: 10px 0">
        <span
          style="
            width: 80px;
            display: inline-block;
            padding-right: 10px;
            text-align: end;
          "
        >所属部门</span>
        <el-select v-model="fileDept" clearable @change="togetPost" placeholder="请选择">
          <el-option
            v-for="item in deptList"
            :key="item.value"
            :label="item.deptmentName"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div style="margin: 10px 0">
        <span
          style="
            width: 80px;
            display: inline-block;
            padding-right: 10px;
            text-align: end;
          "
        >所属岗位</span>
        <el-select v-model="filePost" @change="changePost" clearable placeholder="请选择">
          <el-option
            v-for="item in filePostList"
            :key="item.value"
            :label="item.postName"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div style="margin: 10px 0">
        <span
          style="
            width: 80px;
            display: inline-block;
            padding-right: 10px;
            text-align: end;
          "
        >考核人员</span>
        <el-select
          v-model="userId"
          filterable
          :filter-method="filterName"
          v-loading="showLoading"
          multiple
          collapse-tags
          placeholder="请选择"
          style="width: 300px"
        >
          <el-option
            v-for="item in personList"
            :key="item.id"
            :label="item.userName"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div style="margin: 10px 0">
        <span
          style="
            width: 80px;
            display: inline-block;
            padding-right: 10px;
            text-align: end;
          "
        >完成任务时间</span>
        <el-date-picker
          style="width: 300px !important"
          v-model="assignDate"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </div>
      <div style="margin: 10px 0; display: flex; align-items: center; height: 40px">
        <span
          style="
            width: 80px;
            display: inline-block;
            padding-right: 10px;
            text-align: end;
          "
        >抽题比例</span>
        <el-slider style="width: 290px !important; margin-left: 10px" v-model="percent" show-input></el-slider>
      </div>
      <div style="margin: 10px 0">
        <div style="display: flex">
          <span style="padding-right: 10px; text-align: end; width: 80px">抽取题目</span>
          <el-table
            :data="titlelist22"
            @selection-change="selectionDraw22"
            style="width: 100%"
            border
          >
            <el-table-column prop="fileName" label="文件名称" width="150"></el-table-column>
            <el-table-column prop="fileNo" label="文件编号"></el-table-column>
            <el-table-column prop="titleCount" label="总题数"></el-table-column>
            <el-table-column type="selection" width="55"></el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addAssignTest">确 定</el-button>
        <el-button @click="closeAssignDialog">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import Header from '../../components/architecture/Header.vue'
import { get, post, deleteMethod } from '../../api/http'
export default {
  components: {
    // Header,
  },
  // 定义属性
  data() {
    return {
      extractionType: [], // 抽取类型
      user: {},
      company: '',
      fileName: '',
      elementHeight: 0,
      fileNameList: [],
      tableData: [],
      dialogVisible: false,
      remark: '',
      checkList: [],
      tableRowIndex: null,
      titlelist: [],
      companyUserList: [],
      roleName: '',
      // 分页
      currentPage: 1,
      curSize: 10,
      count: 0,
      // 范畴
      dialogVisibleC: false,
      Ctdata: [],
      defaultProps: {
        label: 'auditTypeName',
        children: 'auditTypelist',
      },
      // 抽题
      isReq: false,
      reqData: {},
      reqData1: {},
      reqData2: {},
      reqData3: {},
      reqData4: {},
      showDialog1: false,
      showDialog2: false,
      showDialog3: false,
      showDialog4: false,
      showDialog5: false,
      // 现场检查
      areaId: '',
      remark1: '',
      nodeID: [],
      nodeKey: [],
      titlelist1: [],
      areaList: [],
      // 文件落地
      deptList: [],
      fileDept: '',
      filePost: '',
      filePostList: [],
      titlelist2: [],
      titlelist22: [],
      userId: [],
      personList: [],
      assignDate: '',
      showAssignDialog: false,
      assignAuditTest: {},
      selectDrawVlue: [],
      selectDrawVlue22: [],
      showLoading: false,
      // 内外审
      auditDept: '',
      checkStatus: '',
      inOutAuditType: '',
      auditFun: [],
      auditFunList: [],
      year: '',
      // 考核细则
      assessType: '',
      remark4: '',
      titlelist4: {},
      // 部门领导审核
      departmentHeads: '',
      titlelist5: [],
      selectDrawVlue5: [],
      percent: 100,
    }
  },
  mounted() {
    this.getElementHeight()
    this.company = this.$store.state.company
      ? this.$store.state.company
      : JSON.parse(sessionStorage.getItem('userInfo')).upCompanyId
    this.user = JSON.parse(sessionStorage.getItem('userInfo'))
    this.getTableList()
    this.getCompanyList()
    this.roleName = JSON.parse(sessionStorage.getItem('userInfo')).roleName
  },
  filters: {
    time: function (e) {
      if (e === null) {
        return ''
      }
      return e.split('T')[0]
    },
  },
  methods: {
    changeCompany(e) {
      this.$store.dispatch('company', e)
    },
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 130)
      })
    },
    getCompanyList() {
      get('/api/Company/GetAllNoPs').then((resp) => {
        if (resp.code == 200) {
          this.companyUserList = resp.data
        }
      })
    },
    searchData() {
      this.currentPage = 1
      this.handleCurrentChange(1)
    },
    async getTableList() {
      //股份公司管理员
      await get(
        '/api/MixAuditHead/GetByNewMixAuditPage?CompId=' +
          this.company +
          '&AuditType=' +
          JSON.parse(sessionStorage.getItem('fromQuery')) +
          '&limit=' +
          this.curSize +
          '&page=' +
          this.currentPage
      )
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.count != 0 && resp.data.length == 0) {
              //解决删除页面最后一条数据时显示空白页面
              this.currentPage = this.currentPage - 1
              this.getTableList()
            } else {
              this.tableData = resp.data
              this.tableDataCount = resp.count
            }
          }
        })
        .then(() => {
          if (this.dialogVisible) {
            this.dialogVisible = false
            this.rowDblclick(this.tableData[0])
          }
        })
    },
    handleSizeChange(e) {
      this.curSize = e
      this.getTableList()
    },
    handleCurrentChange(e) {
      this.currentPage = e
      this.getTableList()
    },
    // 点击表格
    rowClick(row) {
      this.tableRowIndex = row.index
      this.rowItem = row
    },
    rowDblclick(row) {
      this.$router.push('./mixing-detail?rowItem=' + JSON.stringify(row))
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          'background-color': '#E2E8FA !important',
          'font-weight': 'bold !important',
          color: '#555555',
        }
      }
    },
    // 抽题操作
    checkedBtn() {
      this.showDialog1 = this.checkList.indexOf('现场检查') > -1 ? true : false
      this.showDialog2 = this.checkList.indexOf('文件落地') > -1 ? true : false
      this.showDialog3 =
        this.checkList.indexOf('内外审问题点') > -1 ? true : false
      this.showDialog4 = this.checkList.indexOf('考核细则') > -1 ? true : false
      this.showDialog5 =
        this.checkList.indexOf('部门领导审核') > -1 ? true : false
      this.checkList.indexOf('指派考试') > -1 ? this.showAssignStudy() : ''
    },
    drawQuestion() {
      if (this.company == '') {
        this.$message({
          type: 'warning',
          message: '未选择公司',
        })
        return
      }
      this.showDialog1 = false
      this.showDialog2 = false
      this.showDialog3 = false
      this.showDialog4 = false
      this.showDialog5 = false
      this.areaId = ''
      this.fileDept = ''
      this.filePost = ''
      this.titlelist1 = []
      this.titlelist2 = []
      this.checkList = []
      this.assignAuditTest = {}
      this.extractionType = []
      this.auditDept = ''
      this.year = ''
      this.auditFun = []
      this.closeAssignDialog()
      this.getAreaList()
      this.getDeptList()
      this.dialogVisible = true
      this.checkedBtn()
    },
    handleCompny() {
      var companyName = ''
      this.companyUserList.filter((i) => {
        if (i.id == this.company) companyName = i.companyName
      })
      return companyName
    },

    drawReq() {
      this.isReq = true
      var companyName = this.handleCompny()
      this.reqData = {}
      this.reqData1 = {}
      this.reqData2 = {}
      this.reqData3 = {}
      this.reqData4 = {}

      if (this.showDialog1) this.drawReq1(companyName)
      if (this.showDialog2) this.drawReq2(companyName)
      if (this.showDialog3) this.drawReq3(companyName)
      if (this.showDialog4) this.drawReq4(companyName)
      if (this.showDialog5) this.drawReq5(companyName)

      if (
        !this.isReq ||
        (!this.showDialog1 &&
          !this.showDialog2 &&
          !this.showDialog3 &&
          !this.showDialog4 &&
          !this.showDialog5 &&
          this.showAssignDialog)
      ) {
        return
      }
      if (this.userId.length != 0) {
        this.reqAddAssignTest(companyName)
      }

      // if(this.showDialog3){
      //   if(this.reqData1.inOutAudit){
      //        this.getQuestion(this.reqData1)
      //   }
      //   if(this.reqData2.inOutAudit){
      //        this.getQuestion(this.reqData2)
      //   }
      //   if(this.reqData3.inOutAudit){
      //        this.getQuestion(this.reqData3)
      //   }
      //   if(this.reqData4.inOutAudit){
      //        this.getQuestion(this.reqData4)
      //   }
      // }else{
      //    this.getQuestion(this.reqData)
      // }

      if (this.showDialog3) {
        const reqDataList = [
          this.reqData1,
          this.reqData2,
          this.reqData3,
          this.reqData4,
        ]
        reqDataList.forEach((reqData) => {
          if (reqData.inOutAudit) {
            this.getQuestion(reqData)
          }
        })
      } else {
        if (this.checkList[0] == '指派考试') {
          post(
            '/api/MixAuditHead/InsertAssignAuditTestOnly',
            this.reqData.assignAuditTest
          ).then((res) => {
            this.isReq = false
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '抽题成功',
              })
              this.dialogVisible = false
            }
          })
        } else {
          this.getQuestion(this.reqData)
        }
      }

      // if(Object.keys(this.reqData).length != 0){
      //   this.getQuestion(this.reqData)
      // }
      // var time = setTimeout(() => {
      //   post(
      //     '/api/MixAuditHead/InsertMixAudit?AuditType=' +
      //       JSON.parse(sessionStorage.getItem('fromQuery')),
      //     this.reqData
      //   )
      //     .then((res) => {
      //       clearTimeout(time)
      //       this.isReq = false
      //       if (res.code == 200) {
      //         this.$message({
      //           type: 'success',
      //           message: '抽题成功',
      //         })
      //         this.dialogVisible = false
      //         this.selectDrawVlue5 = []
      //         this.departmentHeads = ''
      //         this.titlelist5 = []
      //         this.userId = []
      //         this.currentPage = 1
      //         this.getTableList()
      //       } else {
      //         if (
      //           (res.message == '内外审抽取的题目为空！' ||
      //             res.message == '考核细则抽取的题目为空！') &&
      //           this.checkList.length > 1
      //         ) {
      //           this.$confirm(res.message + '，确定继续抽题?')
      //             .then(() => {
      //               this.checkList.forEach((i, index) => {
      //                 if (
      //                   i == '内外审问题点' &&
      //                   res.message == '内外审抽取的题目为空！'
      //                 ) {
      //                   this.checkList.splice(index, 1)
      //                   this.showDialog3 = false
      //                 } else if (
      //                   i == '考核细则' &&
      //                   res.message == '考核细则抽取的题目为空！'
      //                 ) {
      //                   this.checkList.splice(index, 1)
      //                   this.showDialog4 = false
      //                 }
      //               })
      //               this.drawReq()
      //             })
      //             .catch(() => {})
      //           return
      //         }
      //         this.$message.error(res.message)
      //       }
      //     })
      //     .catch(() => {
      //       this.isReq = false
      //       clearTimeout(time)
      //       this.$message.error('抽题失败')
      //     })
      // }, 5000)
    },

    // 抽题
    getQuestion(e) {
      let queryParams = e
      var time = setTimeout(() => {
        post(
          '/api/MixAuditHead/InsertMixAudit?AuditType=' +
            JSON.parse(sessionStorage.getItem('fromQuery')),
          queryParams
        )
          .then((res) => {
            clearTimeout(time)
            this.isReq = false
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '抽题成功',
              })
              // this.dialogVisible = false
              this.selectDrawVlue5 = []
              this.departmentHeads = ''
              this.titlelist5 = []
              this.userId = []
              this.currentPage = 1
              this.getTableList()
            } else {
              if (
                (res.message == '内外审抽取的题目为空！' ||
                  res.message == '考核细则抽取的题目为空！') &&
                this.checkList.length > 1
              ) {
                this.$confirm(res.message + '，确定继续抽题?')
                  .then(() => {
                    this.checkList.forEach((i, index) => {
                      if (
                        i == '内外审问题点' &&
                        res.message == '内外审抽取的题目为空！'
                      ) {
                        this.checkList.splice(index, 1)
                        this.showDialog3 = false
                      } else if (
                        i == '考核细则' &&
                        res.message == '考核细则抽取的题目为空！'
                      ) {
                        this.checkList.splice(index, 1)
                        this.showDialog4 = false
                      }
                    })
                    this.drawReq()
                  })
                  .catch(() => {})
                return
              }
              this.$message.error(res.message)
            }
          })
          .catch(() => {
            this.isReq = false
            clearTimeout(time)
            this.$message.error('抽题失败')
          })
      }, 5000)
    },

    handleCloseV() {
      this.dialogVisible = false
    },
    handleClose() {
      this.dialogVisibleC = false
    },
    plus(e) {
      if (e.extractCount < e.titleCount) e.extractCount++
      console.log(e, e.extractCount)
    },
    sub(e) {
      if (e.extractCount > 0) e.extractCount--
    },
    test(e) {
      if (/\D/.test(e.extractCount)) e.extractCount = 0
      if (e.extractCount > e.titleCount) e.extractCount = e.titleCount
    },
    handlePictureCardPreview() {
      this.dialogVisiblebig = true
    },
    // 区域抽题
    getAreaList() {
      get('/api/Area/GetByCompId?CompId=' + this.company).then((resp) => {
        if (resp.code == 200) {
          this.areaList = resp.data
        }
      })
    },
    iScheck(e, v) {
      console.log(e, v)
      this.nodeID = v.checkedNodes
      this.nodeKey = v.checkedKeys
    },
    chooseCt() {
      this.dialogVisibleC = true
      this.nodeID = []
      get('/api/AreaAudit/GetAuditTypeList?AreaId=' + this.areaId).then(
        (res) => {
          this.Ctdata = res.data
        }
      )
    },
    getTitle1() {
      this.nodeID = this.nodeID.reverse()
      for (let index = 0; index < this.nodeID.length; index++) {
        if (this.nodeKey.includes(this.nodeID[index].upId)) {
          this.nodeKey = this.nodeKey.filter((item) => {
            return item !== this.nodeID[index].id
          })
        }
      }
      this.listID = []
      for (let index = 0; index < this.nodeKey.length; index++) {
        var item = {
          AuditTypeId: this.nodeKey[index],
        }
        this.listID.push(item)
      }
      post('/api/AreaAudit/GetTitleList?AreaId=' + Number(this.areaId), {
        data: JSON.stringify(this.listID),
      }).then((res) => {
        this.dialogVisibleC = false
        this.titlelist1 = res.data
        this.nodeID = []
      })
    },
    async drawReq1(companyName) {
      if (!this.areaId) {
        this.isReq = false
        this.$message({ type: 'warning', message: '现场检查未选择范畴' })
        return
      }
      var curList = []
      var extractCount = 0
      this.titlelist1.forEach((e) => {
        extractCount = extractCount + Number(e.extractCount)
      })
      for (let index = 0; index < this.titlelist1.length; index++) {
        if (this.titlelist1[index].extractCount != 0) {
          curList = JSON.stringify(this.titlelist1)
          break
        }
      }
      if (!curList) {
        this.isReq = false
        this.$message({ type: 'warning', message: '现场检查未选择范畴' })
        return
      }
      var cname = ''
      var cNO = ''
      this.areaList.forEach((i) => {
        if (i.id == this.areaId) {
          cname = i.areaName
          cNO = i.areaNo
        }
      })
      // 现场
      var areaAudit = {
        areaId: this.areaId,
        jsonData: {
          data: curList,
        },
        mixAreaAudit: {
          id: 0,
          auditAreaNo: cNO,
          auditAreaName: cname,
          companyId: this.company + '',
          companyName: companyName,
          auditType: 0,
          remark: this.remark1,
          chargeUserId: 'string',
          planId: 'string',
          status: 0,
          saveTime: new Date().toISOString(),
          completeTime: new Date().toISOString(),
          saveUserId: this.user.id + '',
          saveUserNo: this.user.userNo,
          saveUserName: this.user.userName,
        },
      }
      this.reqData.areaAudit = areaAudit
      if (this.extractionType.includes('内审自查')) {
        this.reqData1.areaAudit = areaAudit
      } else if (this.extractionType.includes('内审自纠')) {
        this.reqData2.areaAudit = areaAudit
      } else if (this.extractionType.includes('外审自查')) {
        this.reqData3.areaAudit = areaAudit
      } else if (this.extractionType.includes('外审自纠')) {
        this.reqData4.areaAudit = areaAudit
      }
    },
    // 文件抽题
    closeAssignDialog() {
      this.checkList = this.checkList.filter((item) => item !== '指派考试')
      this.showAssignDialog = false
      this.assignDate = ''
      this.userId = []
      // this.personList = []
      this.selectDrawVlue22 = []
    },
    showAssignStudy() {
      this.showAssignDialog = true
    },
    getDeptList() {
      get('/api/Department/GetNoTree?CompId=' + this.company).then((res) => {
        if (res.code == 200) {
          this.deptList = res.data
        }
      })
    },
    togetPost(e) {
      this.filePost = ''
      if (!e) return
      if (this.userId.length != 0) {
        this.userId = []
        this.personList = []
        this.$message({
          type: 'info',
          message: '请重新选择考试人员',
        })
      }
      get('/api/Post?CompId=' + this.company + '&DeptId=' + e).then((res) => {
        if (res.code == 200) {
          this.filePostList = res.data
        }
      })
      get(
        '/api/User/GetByCompByDept?CompId=' +
          this.company +
          '&DeptId=' +
          this.fileDept
      ).then((res) => {
        this.personList = res.data
      })
    },
    changePost(e) {
      this.filePost = e
      if (e) {
        this.getTitle2(e, 1)
        this.getTitle2(e, 2)
      } else {
        this.titlelist2 = []
        this.selectDrawVlue = []
        this.titlelist22 = []
        this.selectDrawVlue22 = []
      }
    },
    getTitle2(e, index) {
      // if (this.userId.length != 0) {
      //   this.userId = []
      //   this.personList = []
      //   this.$message({
      //     type: 'info',
      //     message: '请重新选择考试人员',
      //   })
      // }
      var url =
        index == 1
          ? '/api/MixAudit/GetFileTitleList'
          : '/api/MixAudit/GetTestTitleList'
      get(
        url +
          '?CompId=' +
          this.company +
          '&DeptId=' +
          this.fileDept +
          '&PostId=' +
          e
      ).then((res) => {
        if (index == 1) {
          this.titlelist2 = res.data
          this.selectDrawVlue = []
        } else {
          this.titlelist22 = res.data
          this.selectDrawVlue22 = []
        }
      })
    },
    selectionDraw(e) {
      this.selectDrawVlue = e
    },
    selectionDraw22(e) {
      this.selectDrawVlue22 = e
    },
    async drawReq2(companyName) {
      if (!this.fileDept || !this.filePost) {
        this.$message({
          type: 'warning',
          message: '文件检查未选择部门/岗位',
        })
        this.isReq = false
        return
      }
      var postName = ''
      var deptName = this.handleDept(this.fileDept)
      this.filePostList.forEach((e) => {
        if (e.id === this.filePost) {
          postName = e.postName
        }
      })
      var fileAudit = {
        compId: this.company,
        deptId: this.fileDept,
        postId: this.filePost,
        jsonData: {
          data: JSON.stringify(this.selectDrawVlue),
        },
        mixFileAudit: {
          id: 0,
          companyId: this.company + '',
          companyName: companyName,
          deptId: Number(this.fileDept),
          deptName: deptName,
          postId: this.filePost + '',
          postName: postName,
          remark: '',
          chargeUserId: this.user.id + '',
          planId: '',
          saveUserId: this.user.id + '',
          saveUserNo: this.user.userNo,
          saveUserName: this.user.userName,
          auditType: 0,
          ruleRemark: '',
          status: 0,
          saveTime: new Date().toISOString(),
          completeTime: new Date().toISOString(),
        },
      }
      this.reqData.fileAudit = fileAudit
      if (this.extractionType.includes('内审自查')) {
        this.reqData1.fileAudit = fileAudit
      } else if (this.extractionType.includes('内审自纠')) {
        this.reqData2.fileAudit = fileAudit
      } else if (this.extractionType.includes('外审自查')) {
        this.reqData3.fileAudit = fileAudit
      } else if (this.extractionType.includes('外审自纠')) {
        this.reqData4.fileAudit = fileAudit
      }
    },
    addAssignTest() {
      if (this.userId.length == 0) {
        this.$message({
          type: 'warning',
          message: '请选择考核人员',
        })
        return
      } else if (this.assignDate == '') {
        this.$message({
          type: 'warning',
          message: '请选择时间',
        })
        return
      }
      this.showAssignDialog = false
    },
    reqAddAssignTest(companyName) {
      var deptName = this.handleDept(this.fileDept)
      var postName = ''
      this.filePostList.forEach((e) => {
        if (e.id === this.filePost) {
          postName = e.postName
        }
      })
      var userName = []
      var userId = [] //传值时使用变量防止乱了顺序
      this.personList.forEach((e) => {
        this.userId.forEach((i) => {
          if (e.id === i) {
            userName.push(e.userName)
            userId.push(e.id)
          }
        })
      })
      var fileName = []
      var fileNo = []
      this.selectDrawVlue22.map((i) => {
        fileName.push(i.fileName)
        fileNo.push(i.fileNo)
      })
      this.assignAuditTest = {
        assignUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
        assignUserName: JSON.parse(sessionStorage.getItem('userInfo')).userName,
        remark: '',
        studyUserId: userId.toString(),
        studyUserName: userName.toString(),
        compId: this.company,
        compName: companyName,
        deptId: this.fileDept,
        deptName: deptName,
        postId: this.filePost,
        postName: postName,
        fileNo: fileNo.toString(),
        fileName: fileName.toString(),
        endTime: this.assignDate,
        percent: this.percent,
      }
      this.reqData.assignAuditTest = this.assignAuditTest
      if (this.extractionType.includes('内审自查')) {
        this.reqData1.assignAuditTest = this.assignAuditTest
      } else if (this.extractionType.includes('内审自纠')) {
        this.reqData2.assignAuditTest = this.assignAuditTest
      } else if (this.extractionType.includes('外审自查')) {
        this.reqData3.assignAuditTest = this.assignAuditTest
      } else if (this.extractionType.includes('外审自纠')) {
        this.reqData4.assignAuditTest = this.assignAuditTest
      }

      console.log(
        '抽题：assignAuditTest=',
        this.reqData.assignAuditTest,
        'selectDrawVlue22=',
        this.selectDrawVlue22,
        'fileName=',
        fileName,
        'fileNo=',
        fileNo
      )
    },
    // 内外审
    changeAuditFun() {
      // 自查不能选职能  自纠一定要选
      // if (this.checkStatus == '自查') return
      this.auditFun = ''
      get(
        '/api/InOutAudit/GetFileFunctionNew?CompId=' +
          this.company +
          '&DeptId=' +
          this.auditDept +
          '&Year=' +
          this.year
        // + '&AuditType=' + 1
        // (this.inOutAuditType == '内审' ? 0 : 1)
      ).then((res) => {
        if (res.code == 200) {
          this.auditFunList = res.data
        }
      })
    },
    handleDept(deptId) {
      var deptName = ''
      this.deptList.forEach((e) => {
        if (e.id === deptId) {
          deptName = e.deptmentName
        }
      })
      return deptName
    },
    drawReq3(companyName) {
      if (this.extractionType.length == 0 || !this.auditDept || !this.year) {
        this.$message({
          type: 'warning',
          message: '内外审未完善选择',
        })
        this.isReq = false
        return
      }
      if (
        (this.extractionType.includes('外审自纠') ||
          this.extractionType.includes('内审自纠')) &&
        !this.auditFun.length != 0
      ) {
        this.$message({
          type: 'warning',
          message: '请选择职能',
        })
        this.isReq = false
        return
      }
      var deptName = this.handleDept(this.auditDept)
      var funIdList = []
      if (
        this.auditFun.length != 0 &&
        (this.extractionType.includes('外审自纠') ||
          this.extractionType.includes('内审自纠'))
      ) {
        this.auditFun.map((item) => {
          funIdList.push({ FunctionId: item })
        })
      }
      function generateInOutAudit(inOutAuditType, checkStatus, jsonData) {
        return {
          deptId: this.auditDept,
          jsonData: {
            data: JSON.stringify(jsonData),
          },
          mixInOutAudit: {
            id: 0,
            companyId: this.company + '',
            companyName: companyName,
            deptId: this.auditDept,
            deptName: deptName,
            years: this.year,
            functionId: '',
            functionName: '',
            auditType: 0,
            inOutAuditType: inOutAuditType,
            remark: '',
            chargeUserId: this.user.id + '',
            planId: '',
            status: 0,
            checkStatus: checkStatus,
            saveTime: new Date().toISOString(),
            completeTime: new Date().toISOString(),
            saveUserId: this.user.id + '',
            saveUserNo: this.user.userNo,
            saveUserName: this.user.userName,
          },
        }
      }

      if (this.extractionType.includes('内审自查')) {
        this.reqData1.inOutAudit = generateInOutAudit.call(this, 0, 0, [])
      }
      if (this.extractionType.includes('内审自纠')) {
        this.reqData2.inOutAudit = generateInOutAudit.call(
          this,
          0,
          1,
          funIdList
        )
      }
      if (this.extractionType.includes('外审自查')) {
        this.reqData3.inOutAudit = generateInOutAudit.call(this, 1, 0, [])
      }
      if (this.extractionType.includes('外审自纠')) {
        this.reqData4.inOutAudit = generateInOutAudit.call(
          this,
          1,
          1,
          funIdList
        )
      }
    },
    // 考核细则
    getTitle4() {
      get('/api/ExamineRules/GetTitleList?Name=' + this.assessType).then(
        (resp) => {
          if (resp.code == 200) {
            resp.data.extractCount = 0
            this.titlelist4 = resp.data
            this.titlelist4.titleCount = resp.data.questionCount
          }
        }
      )
    },
    drawReq4(companyName) {
      var examineRules = {
        name: this.assessType,
        extractCount: this.titlelist4.extractCount,
        remark: this.remark4,
        mExamineRules: {
          id: 0,
          companyId: this.company + '',
          companyName: companyName,
          deptId: 0,
          deptName: '',
          postId: '',
          postName: '',
          remark: '',
          chargeUserId: this.user.id + '',
          planId: '',
          saveUserId: this.user.id + '',
          saveUserNo: this.user.userNo,
          saveUserName: this.user.userName,
          auditType: 0,
          ruleRemark: this.remark4,
          status: 0,
          saveTime: new Date().toISOString(),
          completeTime: new Date().toISOString(),
        },
      }
      this.reqData.examineRules = examineRules
      if (this.extractionType.includes('内审自查')) {
        this.reqData1.examineRules = examineRules
      } else if (this.extractionType.includes('内审自纠')) {
        this.reqData2.examineRules = examineRules
      } else if (this.extractionType.includes('外审自查')) {
        this.reqData3.examineRules = examineRules
      } else if (this.extractionType.includes('外审自纠')) {
        this.reqData4.examineRules = examineRules
      }
    },
    // 部门领导审核
    gettitle5() {
      get('/api/MixAudit/GetDeptDutyTitleList').then((resp) => {
        if (resp.code == 200) {
          this.titlelist5 = resp.data
        }
      })
    },
    selectionDraw5(e) {
      this.selectDrawVlue5 = e
    },
    drawReq5(companyName) {
      var deptName = this.handleDept(this.departmentHeads)
      var mixDeptDutyAudit = {
        jsonData: {
          data: JSON.stringify(this.selectDrawVlue5),
        },
        mixDeptDutyAudit: {
          id: 0,
          companyId: this.company + '',
          companyName: companyName,
          deptId: this.departmentHeads,
          deptName: deptName,
          auditType: 0,
          remark: '',
          chargeUserId: 'string',
          planId: 'string',
          status: 0,
          saveTime: new Date().toISOString(),
          completeTime: new Date().toISOString(),
          saveUserId: this.user.id + '',
          saveUserNo: this.user.userNo,
          saveUserName: this.user.userName,
        },
      }
      this.reqData.mixDeptDutyAudit = mixDeptDutyAudit
      if (this.extractionType.includes('内审自查')) {
        this.reqData1.mixDeptDutyAudit = mixDeptDutyAudit
      } else if (this.extractionType.includes('内审自纠')) {
        this.reqData2.mixDeptDutyAudit = mixDeptDutyAudit
      } else if (this.extractionType.includes('外审自查')) {
        this.reqData3.mixDeptDutyAudit = mixDeptDutyAudit
      } else if (this.extractionType.includes('外审自纠')) {
        this.reqData4.mixDeptDutyAudit = mixDeptDutyAudit
      }
    },
    // 删除
    deleteItem() {
      if (this.tableRowIndex == null) {
        this.$message('未选中列表')
        return
      }
      this.$confirm('此操作将永久删除该检查', '是否继续?', '提示')
        .then(() => {
          deleteMethod('/api/MixAuditHead?Id=' + this.rowItem.id)
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功',
                })
                this.tableRowIndex = null
                this.getTableList()
              } else {
                this.$message.error(res.message)
              }
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {})
    },
    filterName(e) {
      if (!e) return
      this.showLoading = true
      get('/api/User?UserNo=' + '&UserName=' + e)
        .then((resp) => {
          this.showLoading = false
          if (resp.code == 200) {
            this.personList = this.personList.concat(resp.data).reverse()
            let map = new Map()
            for (let item of this.personList) {
              if (!map.has(item.userNo)) {
                map.set(item.userNo, item)
              }
            }
            this.personList = [...map.values()]
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('查询失败')
          this.showLoading = false
        })
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../assets/style/Examine_CSS.css';
.body {
  background-color: #e7e9ec;
  height: 100vh;
}
._SelectHeader {
  margin-bottom: 1px;
  img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
  }
}
.table,
.pagination {
  background-color: white;
}
.pagination {
  text-align: end;
  padding: 10px 5px;
}
.contain {
  padding: 0 10px;
  margin-top: 5px;
}
.table {
  padding: 10px 10px 0 10px;
}
.fourbox {
  display: flex;
  align-items: center;
}
.fourbox {
  margin-bottom: 10px;
  & > span {
    font-weight: bold;
    color: black;
    display: inline-block;
    width: 66px;
  }
  & > div {
    margin: 0 10px;
  }
  /deep/.el-checkbox {
    margin-right: 15px !important;
  }
  /deep/.el-checkbox__label {
    padding-left: 5px !important;
    font-size: 12px !important;
  }
}
.draw-contain {
  width: 100%;
  max-height: 480px;
  overflow-y: scroll;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #dbdbdb;
}
/deep/.el-dialog__body {
  padding: 20px !important;
}
.dialog-footer {
  text-align: center !important;
}
/deep/ .el-select__tags {
  flex-wrap: nowrap !important;
  overflow: hidden;
}
/deep/ .el-date-editor.el-input {
  width: 200px !important;
}
/deep/ .el-input-number__decrease,
/deep/ .el-input-number__increase {
  background: none !important;
}
</style>
